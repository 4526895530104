import IGeneric from "../interfaces/IGeneric";

import "../styles/TextInput.css";

import Label from "./Label";

import { handleKeyDown, handleKeyUp, validator } from "../utils/validators";
import { getPrefix, getSuffix } from "../utils/mutate";

import React, { useEffect, useState } from "react";

export const TextInput: React.FC = (props: IGeneric) => {
    const { type, attributes, elementId, onStateChange, onEnter, validations, onError, error, containerRef, inputRef } = props;

    const { value, label, tooltip, placeholder, decimals, textBoxHeight, valueOverride, visibility, noWrap } = attributes;

    const { required, pattern } = validations;

    const getInputValue = (initialValue: string | number | undefined) => {
        return initialValue ? (type === "number" ? parseFloat(initialValue.toString()) : initialValue.toString()) : initialValue;
    }

    const [inputValue, setInputValue] = useState<string | number | undefined>(getInputValue(value));

    const validate = (newValue: string | number | undefined, show: boolean) => {
        const validationMessage = validator(
            newValue,
            type,
            validations,
            attributes,
        );

        if (validationMessage) {
            onError({ validationMessage, show });
        }
        else {
            onStateChange(newValue);
            setInputValue(newValue);
            onError(undefined);
        }
    };

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        validate(getInputValue(event.target.value), true);

        if (error) return;

        handleChange(event);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        let newValue = event.target.value;
        if (pattern) {
            const regex = new RegExp(pattern, "g");
            if (regex.test(newValue)) {
                newValue = newValue.replaceAll(regex, "");
                event.target.value = newValue;
            }
        }
        else if (type === "number") {
            const numValue = parseFloat(newValue).toFixed(decimals ?? 0);
            if (newValue.length > numValue.length)
                event.target.value = numValue;
        }
        validate(newValue, false);
    }

    const prefix = getPrefix(inputValue ? inputValue.toString() : "", attributes) ?? "";

    useEffect(() => {
        if (valueOverride && inputRef?.current) {
            inputRef.current.value = value ?? null;
        }
        // eslint-disable-next-line
    }, [valueOverride]);

    useEffect(() => {
        validate(getInputValue(value), false);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={`input-container text-input ${noWrap}`} id={`${elementId}_container`} ref={containerRef}>
            <div>
                <div>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                    />
                    {type === "textarea" ?
                        <textarea
                            key={elementId}
                            id={elementId}
                            name={elementId}
                            className={error ? "error" : ""}
                            placeholder={placeholder}
                            required={required}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={inputValue}
                            rows={textBoxHeight ?? 4}
                            ref={inputRef}
                            aria-required={required}
                            aria-describedby={`${elementId}_error`}
                            tabIndex={0}
                        /> :
                        <>
                            <div className="relative">
                                <div className="prefix">{prefix}</div>
                                <input
                                    key={elementId}
                                    id={`${type}-${elementId}`}
                                    name={elementId}
                                    className={`${prefix.length > 0 ? "prefix" : ""} ${error && error.show ? "error" : ""}`}
                                    type={type}
                                    inputMode={type}
                                    pattern={pattern}
                                    placeholder={placeholder}
                                    required={required}
                                    maxLength={validations?.maxLength}
                                    minLength={validations?.minLength}
                                    min={validations?.minValue}
                                    max={validations?.maxValue}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={handleKeyDown}
                                    onKeyUp={(event) => handleKeyUp(event, onEnter)}
                                    defaultValue={inputValue}
                                    ref={inputRef}
                                    aria-required={required}
                                    aria-describedby={`${elementId}_error`}
                                    tabIndex={0}
                                />
                                {error && error.show &&
                                    <div className="error-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="12" x2="12" y1="8" y2="12"></line>
                                            <line x1="12" x2="12.01" y1="16" y2="16"></line>
                                        </svg>
                                    </div>
                                }
                                {type === "password" && visibility &&
                                    <button type="button" data-hs-toggle-password={`{
                                    "target": "#${type}-${elementId}"
                                  }`} className="view-password"
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                            <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                            <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                            <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22"></line>
                                            <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                            <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
                                        </svg>
                                    </button>
                                }
                                <div className="suffix">{getSuffix(inputValue ? inputValue.toString() : "", attributes)}</div>
                            </div>
                        </>
                    }
                    <p
                        id={`${elementId}_error`}
                        className="error"
                    >
                        {error && error.show && error.validationMessage}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TextInput;
