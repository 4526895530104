import IGeneric from "../interfaces/IGeneric";

import "../styles/TokenexInput.css";

import Label from "./Label";

import { handleKeyDown, handleKeyUp, validator } from "../utils/validators";
import React, { useEffect, useState, useRef, createRef } from "react";

declare global {
    interface Window {
        TokenEx: any;  // You can replace `any` with a more specific type if you have the typings for TokenEx
    }
}

export const TokenExInput: React.FC = ((props: IGeneric) => {
    const { attributes, elementId, onStateChange, onEnter, validations, onError, error, containerRef, inputRef } = props;
    const { value, label, tooltip, tokenExId, authenticationKey, placeholder, timestamp, includeCardholder, cardholderLabel, cardholderPlaceholder, includeExpiry, expiryLabel, expiryPlaceholder, includeCvv, cvvLabel, cvvPlaceholder, noWrap } = attributes;

    const isDevOrTest = document.location.hostname.startsWith("localhost") || document.location.hostname.startsWith("dev.") || document.location.hostname.startsWith("test.");
    const scriptSrc = (process.env.REACT_APP_TOKENEX_SCRIPT ?? "").replace(!isDevOrTest ? "test-" : "", "");
    const script = document.createElement('script');
    const iframeConfig = {
        origin: document.location.origin,
        timestamp: timestamp ?? "",
        tokenExID: tokenExId ?? "",
        tokenScheme: "PCI",
        authenticationKey: authenticationKey ?? "",
        pci: true,
        maskInput: false,
        enableValidateOnBlur: false,
        enableAriaRequired: true,
        enablePrettyFormat: true,
        returnAutoCompleteValues: true,
        cvv: includeCvv ?? false,
        cvvContainerID: (includeCvv ?? false) ? `${elementId}-cvv` : "",
        placeholder: placeholder ?? "",
        cvvPlaceholder: cvvPlaceholder ?? "",
        styles: {
            base: `${process.env.REACT_APP_TOKENEX_STYLE}`,
            focus: `${process.env.REACT_APP_TOKENEX_STYLE}`,
            error: `${process.env.REACT_APP_TOKENEX_STYLE}`,
            cvv: {
                base: `${process.env.REACT_APP_TOKENEX_STYLE}`,
                focus: `${process.env.REACT_APP_TOKENEX_STYLE}`,
                error: `${process.env.REACT_APP_TOKENEX_STYLE}`,
            }
        }
    };
    const cardnumberDiv = createRef<HTMLDivElement>();
    const cvvDiv = createRef<HTMLDivElement>();

    const [inputValue, setInputValue] = useState<IGeneric>(value);
    var iframe = useRef<any>();

    function validate(show: boolean): void {
        const { validator, cvvValidator, isValid, isCvvValid, cardholder, expiry, token } = inputValue ?? {};
        //console.log(inputValue);
        let newError = {
            ...error,
            show
        }
        newError.cardholder = undefined;
        newError.expiry = undefined;
        newError.cardnumber = undefined;
        newError.cvv = undefined;
        newError.token = undefined;

        if ((validations.required || validator || isValid) && includeCardholder && !cardholder)
            newError.cardholder = "Cardholder Name is required";
        if ((validations.required || validator || isValid) && includeExpiry) {
            if (!expiry) newError.expiry = "Expiry Date is required";
            else if (expiry.length !== 5) newError.expiry = "Please enter a valid Expiry Date";
        }
        if (validations.required && validator === "required" && !isValid)
            newError.cardnumber = validations.requiredMessage ?? "Card Number is required";
        else if (validator === "format" && !isValid)
            newError.cardnumber = validations.validationMessage ?? "Please enter a valid card number";
        else if (validations.required && cvvValidator === "required" && !isCvvValid)
            newError.cvv = "CVV is required";
        else if (cvvValidator === "format" && !isCvvValid)
            newError.cvv = validations.validationMessage ?? "Please enter a valid CVV";
        else if (token === undefined)
            newError.token = "No Token";

        console.log(newError);
        if (newError.cardnumber || newError.cardholder || newError.expiry || newError.cvv || newError.token) {
            onError(newError);
        }
        else {
            onStateChange(inputValue);
            onError(undefined);
        }
    };

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>, property: string) => {
        onError({ ...error, ...{ [`show${property}`]: true } });
        setInputValue(prevInputValue => {
            return { ...prevInputValue, ...{ [property]: event.target.value } };
        });
    };

    const handleTokenize = (tokenization: IGeneric) => {
        //console.log("tokenize");
        //console.log(tokenization);
        //console.log(inputValue);
        setInputValue(prevInputValue => {
            if (prevInputValue?.expiry) {
                const expiryParts = prevInputValue.expiry.split("/");
                if (expiryParts.length === 2) {
                    prevInputValue.expiryMonth = expiryParts[0];
                    prevInputValue.expiryYear = expiryParts[1];
                }
            }
            return { ...prevInputValue, ...tokenization };
        });
    }

    const handleExpiryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const today = new Date();
        const currentValue = event.target.value ?? "";
        let newValue = "";
        for (var i = 0; i < currentValue.length && i < 5; i++) {
            const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
            const c = currentValue.charAt(i);
            switch (i) {
                case 0:
                    if (digits.splice(0, 2).includes(c)) newValue += c;
                    else i = 5;
                    break;
                case 1:
                    switch (newValue) {
                        case ("0"):
                            if (digits.splice(1, 9).includes(c)) newValue += c;
                            else i = 5;
                            break;
                        case ("1"):
                            if (digits.splice(0, 3).includes(c)) newValue += c;
                            else i = 5;
                            break;
                    }
                    break;
                default:
                    if (i === 2 && c === "/") {
                        newValue += c;
                        break;
                    }

                    if (!newValue.includes("/")) newValue += "/";
                    const fullYear = today.getFullYear().toString();
                    if (newValue.length === 3) {
                        const decade = parseInt(fullYear.slice(2, 3));
                        if (digits.includes(c) && parseInt(c) >= decade && parseInt(c) <= (decade + 1)) newValue += c;
                        else i = 5;
                    }
                    else if (digits.includes(c)) {
                        const month = today.getMonth();
                        const year = parseInt(fullYear.slice(2, 4));
                        const expiryMonth = parseInt(newValue.slice(0, 2));
                        const expiryYear = parseInt(newValue.slice(3, 4) + c);
                        if (year < expiryYear || (year === expiryYear && month < expiryMonth)) newValue += c;
                    }
                    break;
            }
        }
        event.target.value = newValue;
    }

    useEffect(() => {
        inputValue && validate(true);
    }, [inputValue]);

    useEffect(() => {
        if (cardnumberDiv.current && cvvDiv.current) {
            const currentCardnumberDiv = cardnumberDiv.current;
            const currentCvvDiv = cvvDiv.current;
            script.src = scriptSrc;
            document.head.appendChild(script);

            script.onload = () => {
                if (window.TokenEx && window.TokenEx.Iframe) {
                    if (!window.TokenEx || !window.TokenEx.Iframe) {
                        console.error("TokenEx Iframe is not available.");
                        return;
                    }

                    iframe.current = new window.TokenEx.Iframe(`${elementId}-cardnumber`, iframeConfig);

                    iframe.current.on("load", () => {
                        currentCardnumberDiv.className = "visible";
                        currentCvvDiv.className = "visible";
                    });
                    iframe.current.on("focus", function () {
                        currentCardnumberDiv.className = "focus";
                    });
                    iframe.current.on("blur", function () {
                        currentCardnumberDiv.className = "visible";
                        currentCardnumberDiv.setAttribute("showError", "yes");
                        iframe.current.tokenize();
                    });
                    iframe.current.on("validate", function (data: IGeneric) {
                        setInputValue(prevInputValue => {
                            return { ...prevInputValue, ...data };
                        });
                    });
                    iframe.current.on("cardTypeChange", function (data: IGeneric) {
                        console.log(data);
                        currentCardnumberDiv.setAttribute("possibleCardType", data.possibleCardType);
                    });
                    iframe.current.on("tokenize", function (data: IGeneric) {
                        handleTokenize(data);
                    });
                    iframe.current.on("error", function (data: IGeneric) {
                    });
                    iframe.current.on("cvvFocus", function () {
                        currentCvvDiv.className = "focus";
                    });
                    iframe.current.on("cvvBlur", function () {
                        iframe.current.tokenize();
                        currentCvvDiv.className = "visible";
                        currentCvvDiv.setAttribute("showError", "yes");
                    });
                    iframe.current.on("autoCompleteValues", function (data: IGeneric) {
                        iframe.current.tokenize();
                    });
                    iframe.current.load();
                } else {
                    console.error("TokenEx is not available or failed to load correctly.");
                }
                document.head.removeChild(script);
            };

            script.onerror = () => {
                console.error("Failed to load the TokenEx script.");
            };
        }
        validate(false);
    }, []);

    return (
        <div className={`input-container text-input tokenex-input ${noWrap}`} id={`${elementId}_container`} ref={containerRef}>
            <div>
                {includeCardholder &&
                    <div className="tokenex-cardholder">
                        <Label
                            elementId={`${elementId}-cardholder`}
                            content={cardholderLabel}
                        />
                        <input
                            id={`${elementId}-cardholder`}
                            name={elementId}
                            className={`${error && error.show && error.showcardholder && error.cardholder ? "error" : ""}`}
                            type="text"
                            inputMode="text"
                            placeholder={cardholderPlaceholder}
                            required={true}
                            maxLength={50}
                            minLength={4}
                            autoComplete="off"
                            //onChange={(event) => handleBlur(event, "cardholder")}
                            onBlur={(event) => handleBlur(event, "cardholder")}
                            onKeyDown={handleKeyDown}
                            onKeyUp={(event) => handleKeyUp(event, onEnter)}
                            defaultValue={value?.cardholder}
                            ref={inputRef}
                            aria-required={true}
                            aria-describedby={`${elementId}_error`}
                            tabIndex={0}
                        />
                        <p
                            id={`${elementId}_error`}
                            className="error"
                        >
                            {error && error.show && error.showcardholder && error.cardholder}
                        </p>
                    </div>
                }
                <div className={`tokenex-cardnumber ${error && error.show && error.cardnumber ? "error" : ""}`}>
                    <Label
                        elementId={elementId}
                        content={label}
                        tooltip={tooltip}
                    />
                    <div
                        key={`${elementId}-cardnumber`}
                        id={`${elementId}-cardnumber`}
                        ref={cardnumberDiv}
                        className="invisible"
                    />
                    <p
                        id={`${elementId}_cardnumber-error`}
                        className="error"
                    >
                        {error && error.show && error.cardnumber}
                    </p>
                </div>
                {includeExpiry &&
                    <div className="tokenex-expiry">
                        <Label
                            elementId={`${elementId}-expiry`}
                            content={expiryLabel}
                        />
                        <input
                            id={`${elementId}-expiry`}
                            name={elementId}
                            className={`${error && error.show && error.showexpiry && error.expiry ? "error" : ""}`}
                            type="text"
                            inputMode="text"
                            pattern="MM/YY"
                            placeholder={expiryPlaceholder}
                            required={true}
                            maxLength={5}
                            minLength={5}
                            autoComplete="off"
                            onChange={handleExpiryChange}
                            onBlur={(event) => handleBlur(event, "expiry")}
                            onKeyDown={handleKeyDown}
                            onKeyUp={(event) => handleKeyUp(event, onEnter)}
                            defaultValue={value?.expiry}
                            ref={inputRef}
                            aria-required={true}
                            aria-describedby={`${elementId}_error`}
                            tabIndex={0}
                        />
                        <p
                            id={`${elementId}_expiry-error`}
                            className="error"
                        >
                            {error && error.show && error.showexpiry && error.expiry}
                        </p>
                    </div>
                }
                {includeCvv &&
                    <div className={`tokenex-cvv ${error && error.show && error.cvv ? "error" : ""}`}>
                        <Label
                            elementId={`${elementId}-cvv`}
                            content={cvvLabel}
                        />
                        <div
                            id={`${elementId}-cvv`}
                            ref={cvvDiv}
                            className="invisible"
                        />
                        <p
                            id={`${elementId}_cvv-error`}
                            className="error"
                        >
                            {error && error.show && error.cvv}
                        </p>
                    </div>
                }
            </div>
        </div>
    );
});

export default TokenExInput;
