import IGeneric from "../interfaces/IGeneric";
import IForm from "../interfaces/IForm";
import IJourneyResponse from "../interfaces/IJourneyResponse";
import IApiResponse from "../interfaces/IApiResponse";
import IHeaders from "../interfaces/IHeaders";

import { msalInstance, clientAppConfig } from "../pages/AuthProvider";

import axios from "axios";
import Cookies, { CookieAttributes } from "js-cookie";
import IMenuItem from "../interfaces/IMenuItem";

const { requiresAuthentication } = clientAppConfig;

const filterUndefinedHeaders = (headers: IHeaders): IHeaders => {
    const filteredHeaders: IHeaders = {};

    for (const key in headers) {
        if (Object.prototype.hasOwnProperty.call(headers, key) && headers[key as keyof IHeaders] !== undefined && headers[key as keyof IHeaders] !== "") {
            filteredHeaders[key as keyof IHeaders] = headers[key as keyof IHeaders];
        }
    }

    return filteredHeaders;
}

const getAuthToken = async () => {
    if (msalInstance) {
        const account = msalInstance.getActiveAccount();
        if (account !== null) {
            const tokenResult = await msalInstance.acquireTokenSilent({ scopes: ["openid", "profile", "user.read"] });
            return `Bearer ${tokenResult.accessToken}`;
        }
    }
    return undefined;
};

export const clearCookies = () => {
    Cookies.remove("sessionIdentifier");
    Cookies.remove("applicantIdentifier");
    Cookies.remove("applicationIdentifier");
    Cookies.remove("lastModified");
};

export const setCookies = (deviceIdentifier: string | undefined, sessionIdentifier: string | undefined, applicantIdentifier: string | undefined, applicationIdentifier: string | undefined, lastModified: string | undefined) => {
    const date = new Date();
    date.setTime(date.getTime() + (parseFloat(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES ?? "0") * 60000));
    const cookieOptions: CookieAttributes = { sameSite: "strict", secure: true, path: "/", expires: date };

    if (deviceIdentifier) Cookies.set("deviceIdentifier", deviceIdentifier, { ...cookieOptions, expires: 365 });
    if (sessionIdentifier) Cookies.set("sessionIdentifier", sessionIdentifier, cookieOptions); else Cookies.remove("sessionIdentifier");
    if (applicantIdentifier) Cookies.set("applicantIdentifier", applicantIdentifier, cookieOptions); else Cookies.remove("applicantIdentifier");
    if (applicationIdentifier) Cookies.set("applicationIdentifier", applicationIdentifier, cookieOptions); else Cookies.remove("applicationIdentifier");
    if (lastModified) Cookies.set("lastModified", lastModified, cookieOptions); else Cookies.remove("lastModified");
}

export const refreshCookies = () => {
    setCookies(Cookies.get("deviceIdentifier"), Cookies.get("sessionIdentifier"), Cookies.get("applicantIdentifier"), Cookies.get("applicationIdentifier"), Cookies.get("lastModified"));
};

export const sendPrinsixRequest = async (
    updateHeaders: (result: IHeaders) => void,
    headerState: IHeaders,
    currentForm: IForm | undefined,
    formData: IGeneric | undefined,
    resumeHeader: IGeneric | undefined,
    menuItem: IMenuItem | undefined,
): Promise<IApiResponse> => {
    const requestHeaders: IGeneric = {
        accept: "application/json",
        "content-type": "application/json",
        clientUrl: window.location,
        deviceIdentifier: Cookies.get("deviceIdentifier"),
        sessionIdentifier: Cookies.get("sessionIdentifier"),
        applicantIdentifier: Cookies.get("applicantIdentifier"),
        applicationIdentifier: Cookies.get("applicationIdentifier"),
        workflow: currentForm?.workflow,
        actionId: currentForm?.actionId,
        form: currentForm?.name,
        ...resumeHeader,
        ...menuItem
    };

    let newRequestHeaders: IGeneric = {};
    const additionalRequestHeaders = filterUndefinedHeaders(headerState);

    newRequestHeaders = {
        ...requestHeaders,
        ...additionalRequestHeaders
    };


    if (requiresAuthentication)
        newRequestHeaders.authorization = await getAuthToken();

    const response = await axios({
        url: `${process.env.REACT_APP_API_DOMAIN ? `https://${process.env.REACT_APP_API_DOMAIN}` : ``}/api/ui/?version=${process.env.REACT_APP_API_VERSION}`,
        method: "POST",
        data: formData || {},
        headers: newRequestHeaders,
    });

    const { status, data, headers } = response;

    const { "deviceidentifier": deviceIdentifier, "content-type": contentType, "sessionidentifier": sessionIdentifier, "applicantidentifier": applicantIdentifier, "applicationidentifier": applicationIdentifier, "lastmodified": lastModified } = headers;

    setCookies(deviceIdentifier, sessionIdentifier, applicantIdentifier, applicationIdentifier, lastModified);
    updateHeaders({ deviceIdentifier, "content-type": contentType, sessionIdentifier, applicantIdentifier, applicationIdentifier, lastModified });


    const journeyResponse: IJourneyResponse = {
        form: data.form ?? {},
        menus: data.menus,
        formHistory: data.formHistory ?? null,
    };

    if (journeyResponse.formHistory !== null) {
        journeyResponse.formHistory = journeyResponse.formHistory
            .filter((form: IForm) => !(form.name === journeyResponse.form.name && form.workflow === journeyResponse.form.workflow));
    }

    return { status, journeyResponse };

}
